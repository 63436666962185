export enum TradeStatus{
	"Invalid"= '1',
	"Active"= '2',            // This trade offer has been sent, neither party has acted on it yet.
	"Accepted"= '3',          // The trade offer was accepted by the recipient and items were exchanged.
	"Countered"= '4',         // The recipient made a counter offer
	"Expired"= '5',           // The trade offer was not accepted before the expiration date
	"Canceled"= '6',          // The sender cancelled the offer
	"Declined"= '7',          // The recipient declined the offer
	"InvalidItems"= '8',      // Some of the items in the offer are no longer available (indicated by the missing flag in the output)
	"CreatedNeedsConfirmation"= '9', // The offer hasn't been sent yet and is awaiting further confirmation
	"CanceledBySecondFactor"= '10', // Either party canceled the offer via email/mobile confirmation
	"InEscrow"= '11',          // The trade has been placed on hold

	"_1"= "Invalid",
	"_2"= "Active",
	"_3"= "Accepted",
	"_4"= "Countered",
	"_5"= "Expired",
	"_6"= "Canceled",
	"_7"= "Declined",
	"_8"= "InvalidItems",
	"_9"= "CreatedNeedsConfirmation",
	"_10"= "CanceledBySecondFactor",
	"_11"= "InEscrow",
};