import React, { useState, useEffect } from "react";
import useFetch from "../../../hooks/useFetch.ts";
import BotsFilter from "./BotsFilter.js";
import BotsTable from "./BotsTable.js";
import Pagination from "./Pagination.js";
import "./BotsBlock.css";
import { useTable, usePagination, useGlobalFilter } from "react-table";

const BotsBlock = () => {
  const [pageNumber, setPageNumber] = useState(1);

  const dataBots = useFetch("/api/bots_steam", {
    method: "GET",
    queryParams: { page: pageNumber },
  });


  const [bots, setBots] = useState([]);

  const [searchTerms, setSearchTerms] = useState({
    SteamID: "",
  });

  useEffect(() => {
    if (dataBots.data) setBots(dataBots.data.data);
  }, [dataBots.data]);


  const columns = React.useMemo(
    () => [
      {                                 
        Header: "Ава",
        accessor: "avatar",
        Cell: ({ row, value }) =>
          <img className="bots-table-img" src={value} />,
      },
      {
        Header: "SteamID",
        accessor: "SteamID",
      },
      {
        Header: "Принимаем?",
        accessor: "reception",
        Cell: ({ row, value }) =>
          <input key={row.original.id} type="checkbox" checked={value} />,
      },
      {
        Header: "Приняли в $",
        Cell: ({ row, value }) =>
          <p >{row.original.nowReceptionUsd.toFixed(2)} $ / {row.original.limitReceptionUsd} $ </p>,
     
      },
      {
        Header: "Приняли кол-во в Cs",
        Cell: ({ row, value }) =>
          <p >{row.original.now_730_items} / {row.original.limit_730_items} </p>,
     
      },
      {
        Header: "Приняли кол-во в Dota2",
        Cell: ({ row, value }) =>
          <p >{row.original.now_570_items} / {row.original.limit_570_items} </p>,
     
      },
      {
        Header: "Приняли кол-во в Tf2",
        Cell: ({ row, value }) =>
          <p >{row.original.now_440_items} / {row.original.limit_440_items} </p>,
     
      },
      {
        Header: "Приняли кол-во в Rust",
        Cell: ({ row, value }) =>
          <p >{row.original.now_252490_items} / {row.original.limit_252490_items} </p>,
     
      },
    
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state: { pageIndex, globalFilter },
    gotoPage,
    previousPage,
    nextPage,
  } = useTable(
    {
      columns,
      data: bots,
      initialState: { pageSize: 30 },
    },
    useGlobalFilter,
    usePagination
  );

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected + 1)

    dataBots.refetch("/api/bots_steam", {
      method: "GET",
      queryParams: { page: selected + 1, ...searchTerms },
    });
  };


  const handleSearchChange = (field) => (e) => {
    const newSearchTerms = {
      ...searchTerms,
      [field]: e.target.value,
    };
    setSearchTerms(newSearchTerms);

    dataBots.refetch("/api/bots_steam", {
      method: "GET",
      queryParams: { page: pageNumber, ...newSearchTerms },
    });
  };


  return (
    <div className="bots-container">
      <div>
        <BotsFilter
          searchTerms={searchTerms}
          handleSearchChange={handleSearchChange}
        />
        <BotsTable
          data={{
            getTableProps,
            getTableBodyProps,
            headerGroups,
            page,
            prepareRow,
            setGlobalFilter,
            canPreviousPage,
            canNextPage,
            pageOptions,
            state: { pageIndex, globalFilter },
            gotoPage,
            previousPage,
            nextPage,
          }}
        />
      </div>

      <Pagination
        pageCount={dataBots.data?.count / 30}
        handlePageClick={handlePageClick}
        pageIndex={pageIndex}
      />
    
    </div>
  );
};

export default BotsBlock;
