import React from "react";

const BotsFilter = ({ searchTerms, handleSearchChange }) => {
  return (
    <div className="bots-container_filter">
      <input
        value={searchTerms.SteamID}
        onChange={handleSearchChange("SteamID")}
        placeholder="Search by SteamID"
      />
    </div>
  );
};

export default BotsFilter;
