import React from "react";
import UsersBlock from "../components/component/UsersBlock/UsersBlock";

function Users() {
  return (
    <div className="container">
      <UsersBlock />
    </div>
  );
}

export default Users;
