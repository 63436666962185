import React, { useState, useEffect } from "react";
import useFetch from "../../../hooks/useFetch.ts";
import useWithMethodFetch from "../../../hooks/useWithMethodFetch.ts";
import SkinsFilter from "./SkinsFilter.js";
import SkinsTable from "./SkinsTable.js";
import Pagination from "./Pagination.js";
import { useTable, usePagination, useGlobalFilter } from "react-table";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import "./SkinsBlock.css";
import { formatDate } from "../../../util/formatDate.ts";

let querys = {
  game: "730",
  hash_name: "",
  isOnlyAllowed: true,
  isOnlyBostRuchie: '',
}

const SkinsBlock = () => {
  const [pageNumber, setPageNumber] = useState(1);
  const updateSkin = useWithMethodFetch(`/api/skins-updater/prices/setting`);

  const [skins, setSkins] = useState([]);

  const [searchTerms, setSearchTerms] = useState({
    game: "730",
    hash_name: "",
    isOnlyAllowed: true,
    isOnlyBostRuchie: '',
  });

  const dataSkins = useFetch("/api/skins-updater/prices/setting", {
    method: "GET",
    queryParams: { page: pageNumber, ...searchTerms },
  });

  useEffect(() => {

    if (dataSkins.data) setSkins(dataSkins.data.data);
  }, [dataSkins.data]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "hash_name",
        
        Cell: ({ row, value }) =>
          <CopyToClipboard
            text={value}
            onCopy={() => toast.success("Скопировано")}
          >
            <span className="skins-container-title-skin">{value}</span>
          </CopyToClipboard>,
      },
      {
        Header: "Цена",
        accessor: "priceBuy",
        Cell: ({ row, value }) =>
          <span onClick={(e) => {
            const res = window.prompt(`Укжите новую цену в $:`)
            if (res !== null)
              updateSkinFunc(row.original.id, 'priceBuy', +res)

          }} className="skins-container-price-skin">{!value ? 'Нет цены' : `${value} $`}</span>,
      },
      {
        Header: "Игра",
        accessor: "game",
      },
      {
        Header: "Ликвидность",
        accessor: "liquidity",
        Cell: ({ row, value }) =>
          value && <span style={{
            color: value < 50 ? '#c56262' : value < 75 ? '#c0ad2d' : '#51c02d'
          }} className="skins-container-liquidity-skin">{(+value).toFixed(1)}</span>,
      },
      {
        Header: "Продаж за 7д (30д, cs)",
        accessor: "count_sell",
      },
      {
        Header: "Принимаем?",
        accessor: "allowed",
        Cell: ({ row, value }) =>
          <input key={row.original.id} type="checkbox" onChange={(e) => updateSkinFunc(row.original.id, 'allowed', e.target.checked)} defaultChecked={value} />,
      },
      {
        Header: "Обновляем?",
        accessor: "update",
        Cell: ({ row, value }) =>
          <input key={row.original.id} type="checkbox" onChange={(e) => updateSkinFunc(row.original.id, 'update', e.target.checked)} defaultChecked={value} />,
      },
      {
        Header: "Ласт апдейт",
        accessor: "updatedAt",
        Cell: ({ row, value }) =>
          <span>{formatDate(value)}</span>,
      },
      {
        Header: "Доп. Процент",
        accessor: "additionalPercent",
        Cell: ({ row, value }) =>
          <span
            className="skins-container-price-skin-integrationPrice"
            onClick={(e) => {
              const res = window.prompt(`Укажите новый процент (только число от 0-100) %:`)
              if (res !== null)
                updateSkinFunc(row.original.id, 'additionalPercent', +res)
            }}
          >- {value} %</span>,
      },
      {
        Header: "Цены интеграций",
        accessor: "metaPrice",
        Cell: ({ row, value }) =>
          <div>{Object.keys(value).map(titleShop =>
            <span
              className="skins-container-price-skin-integrationPrice"
              onClick={(e) => {
                const res = window.prompt(`Укжите новую цену для ${titleShop} в $:`)
                if (res !== null)
                  updateSkinFunc(row.original.id, 'metaPrice', { ...row.original.metaPrice, [titleShop]: +res })
              }}
            >{titleShop}: {value[titleShop]}$</span>
          )}
          </div>,
      },
      {
        Header: "Коммент.",
        accessor: "comment",
      },
      // {
      //   Header: "Tm min",
      //   accessor: "tm_min",
      //   Cell: ({ row, value }) =>
      //     <p style={{'width': 'max-content'}}>{value ? `${value} $` : '-'} </p>,
      // },
      
      {
        Header: "Tm avg",
        accessor: "tm_avg",
        Cell: ({ row, value }) =>
          <p style={{'width': 'max-content'}}>{value ? `${value} $` : '-'} </p>,
      },
      {
        Header: "Buff163 min",
        accessor: "buff_min",
        Cell: ({ row, value }) =>
          <p style={{'width': 'max-content'}}>{value ? `${value} $` : '-'} </p>,
      },
      // {
      //   Header: "Buff163 avg",
      //   accessor: "buff_avg",
      //   Cell: ({ row, value }) =>
      //     <p style={{'width': 'max-content'}}>{value ? `${value} $` : '-'} </p>,
      // },
      {
        Header: "Steam order",
        accessor: "steam_order",
        Cell: ({ row, value }) =>
          <p style={{'width': 'max-content'}}>{value ? `${value} $` : '-'} </p>,
      },
      // {
      //   Header: "MarketTf avg",
      //   accessor: "marketplace_avg",
      //   Cell: ({ row, value }) =>
      //     <p style={{'width': 'max-content'}}>{value ? `${value} $` : '-'} </p>,
      // },
    ],
    []
  );

  const {
    page,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state: { pageIndex, globalFilter },
    gotoPage,
    previousPage,
    nextPage,
  } = useTable(
    {
      columns,
      data: skins,
      initialState: { pageSize: 100 },
    },
    useGlobalFilter,
    usePagination
  );

  const updateSkinFunc = async (id, column, value) => {
    await updateSkin.request({
      method: "PATCH",
      body: {
        id, column, value
      }
    });


    dataSkins.refetch("/api/skins-updater/prices/setting", {
      method: "GET",
      queryParams: { page: pageNumber, ...querys },
    });
  }


  const handlePageClick = ({ selected }) => {
    setPageNumber(selected + 1)

    dataSkins.refetch("/api/skins-updater/prices/setting", {
      method: "GET",
      queryParams: { page: selected + 1, ...searchTerms },
    });
  };

  const handleSearchChange = (field) => (e) => {

    const newSearchTerms = {
      ...searchTerms,
      [field]: e.target?.type == "checkbox" ? e.target.checked : e.target.value,
    };

    querys = newSearchTerms
   

    setSearchTerms(newSearchTerms);



    dataSkins.refetch("/api/skins-updater/prices/setting", {
      method: "GET",
      queryParams: { page: pageNumber, ...querys },
    });
  };


  return (
    <div className="skins-container">
      <div>
        <SkinsFilter
          searchTerms={searchTerms}
          handleSearchChange={handleSearchChange}
        />
        <SkinsTable
          data={{
            getTableProps,
            getTableBodyProps,
            headerGroups,
            page,
            prepareRow,
            setGlobalFilter,
            canPreviousPage,
            canNextPage,
            pageOptions,
            state: { pageIndex, globalFilter },
            gotoPage,
            previousPage,
            nextPage,
          }}
        />
      </div>

      <Pagination
        pageCount={dataSkins.data?.count / 10}
        handlePageClick={handlePageClick}
        pageIndex={pageIndex}
      />

    </div>
  );
};

export default SkinsBlock;
