import React from "react";

const UsersFilter = ({ searchTerms, handleSearchChange }) => {
  return (
    <div className="users-container_filter">
      <input
        value={searchTerms.SteamID}
        onChange={handleSearchChange("SteamID")}
        placeholder="Search by SteamID"
      />
      <input
        value={searchTerms.email}
        onChange={handleSearchChange("email")}
        placeholder="Search by Email"
      />
      <input
        value={searchTerms.tradeurl}
        onChange={handleSearchChange("tradeurl")}
        placeholder="Search by Trade URL"
      />
      <input
        value={searchTerms.role}
        onChange={handleSearchChange("role")}
        placeholder="Search by Role"
      />
    </div>
  );
};

export default UsersFilter;
