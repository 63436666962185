import React from "react";

const UsersFilter = ({ searchTerms, handleSearchChange }) => {
  return (
    <div className="skins-container_filter">
      <input
        value={searchTerms.SteamID}
        onChange={handleSearchChange("hash_name")}
        placeholder="Search by Name"
      />
      <select
        value={searchTerms.game}
        onChange={handleSearchChange("game")}
        defaultValue={"730"}
      >
        <option value="730">CS:GO</option>
        <option value="570">Dota 2</option>
        <option value="252490">Rust</option>
        <option value="440">Team Fortress 2</option>
      </select>

      <div className="skins-container-filter-only-allowed">
        <label style={{color: 'white'}} >Только которые принимаем</label>
        <input
          defaultChecked={searchTerms.isOnlyAllowed}
          onChange={handleSearchChange("isOnlyAllowed")}
          type="checkbox"
        />
      </div>

      <div className="skins-container-filter-only-allowed">
        <label style={{color: 'white'}} >Ручные/Буст</label>
        <input
          defaultChecked={searchTerms.isOnlyBostRuchie}
          onChange={handleSearchChange("isOnlyBostRuchie")}
          type="checkbox"
        />
      </div>

    </div>
  );
};

export default UsersFilter;
