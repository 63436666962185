import React from "react";
import Modal from "react-modal";

const MetaModal = ({ modalIsOpen, closeModal, metaData }) => {
  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={closeModal}
      contentLabel="Meta Data"
    >
      <h2>Meta Data</h2>
      <pre>{JSON.stringify(metaData, null, 2)}</pre>
      <button onClick={closeModal}>Close</button>
    </Modal>
  );
};

export default MetaModal;
