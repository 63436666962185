import React from "react";

import BotsBlock from "../components/component/BotsBlock/BotsBlock";

function Bots() {
  return (
    <div className="container">
      <BotsBlock />
    </div>
  );
}

export default Bots;
