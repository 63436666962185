import React from "react";
import SkinsBlock from "../components/component/SkinsBlock/SkinsBlock";

function Skins() {
  return (
    <div className="container">
      <SkinsBlock />
    </div>
  );
}

export default Skins;
