import { useState } from "react";
import { apiRequest } from "../util/apiRequest.ts";
import { toast } from "react-toastify";

const useWithMethodFetch = (endpoint: string) => {
  const [data, setData] = useState<any | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const request = async (options: { method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH'; body?: Record<string, any>; headers?: Record<string, string> }) => {
    setLoading(true);
    try {
      const responseData = await apiRequest<any>(endpoint, options);
      setData(responseData);
      toast.success('Обновлено');
    } catch (err) {
      setError(err as Error);

      toast.error(err.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return { data, loading, error, request };
};

export default useWithMethodFetch;
