import { useState, useEffect, useCallback } from 'react';
import { apiRequest } from '../util/apiRequest.ts';

interface FetchOptions {
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
  body?: Record<string, any>;
  headers?: Record<string, string>;
  params?: Record<string, any>; // Updated parameter for queryParams
}

const useFetch = <T>(initialUrl: string, initialOptions: FetchOptions = { method: 'GET' }) => {
  const [url, setUrl] = useState(initialUrl);
  const [options, setOptions] = useState(initialOptions);
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = useCallback(async () => {

    setLoading(true);
    setError(null);
    setData(null); // Clear previous data before new fetch
    
    try {
      console.log(options)
      const responseData = await apiRequest<T>(url, { ...options });
      setData(responseData);
    } catch (error) {
      console.error("Fetch error:", error);
      setError(error as Error);
    } finally {
      setLoading(false);
    }
  }, [url, options]);

  // useEffect(() => {
  //   fetchData();
  // }, [fetchData]);

  const refetch = (newUrl?: string, newOptions?: FetchOptions) => {
    setUrl(prevUrl => newUrl || prevUrl);

    setOptions(prevOptions => ({ ...prevOptions, ...newOptions }));
  };

  useEffect(() => {
    if (url && options) {
      fetchData();
    
    }
  }, [url, options, fetchData]);

  return { data, loading, error, refetch };
};

export default useFetch;
