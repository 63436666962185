import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Header.css"; // Подключение стилей
import useUser from "../hooks/user/useUser.ts";
import { Role } from "../constants/role.ts";

const Header = () => {
  const user = useUser();
  return (
    <header>
      <nav>

        {[Role.Admin].includes(user?.role) && <div >
          <Link to="/users">Юзеры</Link>
        </div>}


        {[Role.Admin].includes(user?.role) && <div >
          <Link to="/skins">Скины</Link>
        </div>}

        {[Role.Admin].includes(user?.role) && <div >
          <Link to="/skins-on-bots">Скины на ботах</Link>
        </div>}


        {[Role.Manager, Role.Admin].includes(user?.role) && <div >
          <Link to="/transaction">Транзакции</Link>
        </div>}

        
        {[Role.Admin].includes(user?.role) && <div >
          <Link to="/bots">Боты</Link>
        </div>}

      </nav>
    </header>
  );
};

export default Header;
