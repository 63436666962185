import React, { useState, useEffect } from "react";
import { useDebounce } from "use-debounce";
import useFetch from "../../../hooks/useFetch.ts";
import useWithMethodFetch from "../../../hooks/useWithMethodFetch.ts";
import UsersFilter from "./UsersFilter.js";
import UsersTable from "./UsersTable.js";
import Pagination from "./Pagination.js";
import MetaModal from "./MetaModal.js";
import { useTable, usePagination, useGlobalFilter } from "react-table";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import "./UsersBlock.css";
import { formatDate } from "../../../util/formatDate.ts";

const UsersBlock = () => {
  const [pageNumber, setPageNumber] = useState(1);

  const dataUsers = useFetch("/api/user/all", {
    method: "GET",
    queryParams: { page: pageNumber },
  });

  const updateUser = useWithMethodFetch(`/api/user/update`);

  const [users, setUsers] = useState([]);
  const [editIndex, setEditIndex] = useState({ index: -1, idUser: null });
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [metaData, setMetaData] = useState({});
  const [searchTerms, setSearchTerms] = useState({
    SteamID: "",
    email: "",
    tradeurl: "",
    role: "",
  });

  useEffect(() => {
    if (dataUsers.data) setUsers(dataUsers.data.data);
  }, [dataUsers.data]);


  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "SteamID",
        accessor: "SteamID",
      },
      {
        Header: "Дата Рег.",
        accessor: "createdAt",
        Cell: ({ row, value }) =>
          <span>{formatDate(value)}</span>,
      },
      {
        Header: "🔄 -> 🟢",
        accessor: "totalTransactions",
      },
      {
        Header: "🔄💲 -> 🟢",
        accessor: "totalCost",
        Cell: ({ row, value }) =>
          <span>{value} $</span>,
      },

      {
        Header: "🔄 -> 🟡",
        accessor: "paymentingTransactions",
      },
      {
        Header: "🔄💲 -> 🟡",
        accessor: "paymentingCost",
        Cell: ({ row, value }) =>
          <span>{value} $</span>,
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ row, value }) =>
          editIndex === row.index ? (
            <input
              value={value}
              onChange={(e) =>
                handleEditField(row.index, "email", e.target.value)
              }
            />
          ) : (
            value
          ),
      },
      {
        Header: "Password",
        accessor: "password",
        Cell: ({ row, value }) =>
          editIndex === row.index ? (
            <input
              type="password"
              value={value}
              onChange={(e) =>
                handleEditField(row.index, "password", e.target.value)
              }
            />
          ) : (
            value
          ),
      },
      {
        Header: "Trade URL",
        accessor: "tradeurl",
        Cell: ({ row, value }) =>
          editIndex === row.index ? (
            <input
              value={value}
              onChange={(e) =>
                handleEditField(row.index, "tradeurl", e.target.value)
              }
            />
          ) : (
            <CopyToClipboard
              text={value}
              onCopy={() => toast.success("Скопировано")}
            >
              <button>Copy</button>
            </CopyToClipboard>
          ),
      },
      {
        Header: "Role",
        accessor: "role",
        Cell: ({ row, value }) =>
          editIndex === row.index ? (
            <input
              value={value}
              onChange={(e) =>
                handleEditField(row.index, "role", e.target.value)
              }
            />
          ) : (
            value
          ),
      },
      {
        Header: "Type Auth",
        accessor: "typeAuth",
      },
      {
        Header: "Meta",
        accessor: "meta",
        Cell: ({ value }) => (
          <button onClick={() => openModal(value)}>View Meta</button>
        ),
      },
      {
        Header: "Actions",
        Cell: ({ row }) => (
          <button onClick={() => toggleEdit(row.index)}>
            {editIndex === row.index ? "Save" : "Edit"}
          </button>
        ),
      },
    ],
    [editIndex]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    state: { pageIndex, globalFilter },
    gotoPage,
    previousPage,
    nextPage,
  } = useTable(
    {
      columns,
      data: users,
      initialState: { pageSize: 30 },
    },
    useGlobalFilter,
    usePagination
  );

  const handleEditField = (index, field, value) => {
    const updatedUsers = [...users];
    updatedUsers[index][field] = value;
    setUsers(updatedUsers);
  };

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected + 1)

    dataUsers.refetch("/api/user/all", {
      method: "GET",
      queryParams: { page: selected + 1, ...searchTerms },
    });
  };

  const toggleEdit = (index) => {
    if (editIndex === index) {
      const userToUpdate = users[index];

      updateUser.request({
        method: "PATCH",
        body: {
          email: userToUpdate.email && userToUpdate.email.trim(),
          tradeurl: userToUpdate.tradeurl && userToUpdate.tradeurl.trim(),
          role: userToUpdate.role.trim(),
          id: userToUpdate.id,
        },
      });

      setEditIndex(-1);

    } else {
      setEditIndex(index);
    }
  };

  const handleSearchChange = (field) => (e) => {
    const newSearchTerms = {
      ...searchTerms,
      [field]: e.target.value,
    };
    setSearchTerms(newSearchTerms);

    dataUsers.refetch("/api/user/all", {
      method: "GET",
      queryParams: { page: pageNumber, ...newSearchTerms },
    });
  };

  const openModal = (meta) => {
    setMetaData(meta);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <div className="users-container">
      <div>
        <UsersFilter
          searchTerms={searchTerms}
          handleSearchChange={handleSearchChange}
        />
        <UsersTable
          data={{
            getTableProps,
            getTableBodyProps,
            headerGroups,
            page,
            prepareRow,
            setGlobalFilter,
            canPreviousPage,
            canNextPage,
            pageOptions,
            state: { pageIndex, globalFilter },
            gotoPage,
            previousPage,
            nextPage,
          }}
        />
      </div>

      <Pagination
        pageCount={dataUsers.data?.count / 30}
        handlePageClick={handlePageClick}
        pageIndex={pageIndex}
      />
      <MetaModal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        metaData={metaData}
      />
    </div>
  );
};

export default UsersBlock;
