import React from "react";
import TransactionBlock from "../components/component/TransactionBlock/TransactionBlock";


function Transaction() {
  return (
    <div className="container">
      <TransactionBlock />
    </div>
  );
}

export default Transaction;
