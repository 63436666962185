import React from "react";
import SkinsOnBotsBlock from "../components/component/SkinsOnBotsBlock/SkinsOnBotsBlock";

function SkinsOnBots() {
  return (
    <div className="container">
      <SkinsOnBotsBlock />
    </div>
  );
}

export default SkinsOnBots;
