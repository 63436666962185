import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Users from "./page/Users";
import { Role } from "./constants/role.ts";
import LoginContainer from "./page/Login";
import useUser from "./hooks/user/useUser.ts";
import Skins from "./page/Skins.js";
import Transaction from "./page/Transaction.js";
import SkinsOnBots from "./page/SkinsOnBots.js";
import Bots from "./page/Bots.js";

function App() {
  const user = useUser();

  if (user && [Role.Admin].includes(user?.role))
    return (
      <Router>
        <Header />

        <Routes>
          <Route path="/" element={<Users />} />
          <Route path="/users" element={<Users />} />
          <Route path="/skins" element={<Skins />} />
          <Route path="/transaction" element={<Transaction />} />
          <Route path="/skins-on-bots" element={<SkinsOnBots />} />
          <Route path="/bots" element={<Bots />} />
        </Routes>
      </Router>
    );
  else return <LoginContainer />;
}

export default App;
