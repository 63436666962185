import React, { useEffect, useState } from "react";
import "./SkinsOnBotsBlock.css";
import useFetch from "../../../hooks/useFetch.ts";
import Pagination from "../SkinsOnBotsBlock/Pagination";
import SkinsOnBotsBlockFilter from "./SkinsOnBotsBlockFilter.js";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";

const SkinsOnBotsBlock = () => {
  const [pageNumber, setPageNumber] = useState(1);

  const dataSkins = useFetch("/api/bots_steam/skins-on-bots", {
    method: "GET",
    queryParams: { page: pageNumber, game: '730' },
  });

  const [skins, setSkins] = useState([]);

  const [searchTerms, setSearchTerms] = useState({
    game: "730",
    hash_name: "",
    orderBy: 'createdAt||desc'
  });

  useEffect(() => {

    if (dataSkins.data) setSkins(dataSkins.data.data);
  }, [dataSkins.data]);

  const handlePageClick = ({ selected }) => {
    setPageNumber(selected + 1)

    dataSkins.refetch("/api/bots_steam/skins-on-bots", {
      method: "GET",
      queryParams: { page: selected + 1, ...searchTerms },
    });
  };

  const handleSearchChange = (field) => (e) => {
    const newSearchTerms = {
      ...searchTerms,
      [field]: e.target.value,
    };
    setSearchTerms(newSearchTerms);

    dataSkins.refetch("/api/bots_steam/skins-on-bots", {
      method: "GET",
      queryParams: { page: pageNumber, ...newSearchTerms },
    });
  };

  return (
    <div className="skins-on-bot-on-bot-container">
      <div >
        <SkinsOnBotsBlockFilter
          searchTerms={searchTerms}
          handleSearchChange={handleSearchChange}
        />

        <div className="skins-on-bot-on-bot-container-skins">
          {skins.map(item => {
            const img =
              item.icon_url && item.icon_url.includes("http")
                ? item.icon_url
                : `https://community.akamai.steamstatic.com/economy/image/${item.icon_url}`;

            const colorBG = item.priceOnTransaction > 5 ? { 'background-color': 'rgb(149 149 149 / 16%)' } : { 'background-color': '#191c1e' }

            let profitPercent = 0
            switch (item.appid) {
              case '440':
                profitPercent = +(-(1 - item.marketplace_avg * 0.91 / item.priceOnTransaction) * 100).toFixed(0)
                break;
              case '730':
                profitPercent = +(-(1 - item.tm_avg * 0.91 / item.priceOnTransaction) * 100).toFixed(0)
                break;
              case '570':
                profitPercent = +(-(1 - item.tm_avg * 0.91 / item.priceOnTransaction) * 100).toFixed(0)
                break;
              case '252490':
                profitPercent = +(-(1 - item.tm_avg * 0.91 / item.priceOnTransaction) * 100).toFixed(0)
                break;
              default:
                break;
            }

            return <ContextMenuTrigger id={`contextmenu-${item.assetId}`}>
              <div className="skins-on-bot-on-bot-container-skins-skin" style={colorBG} >
                <CopyToClipboard
                  text={item.hash_name}
                  onCopy={() => toast.success("Скопировано")}
                >
                  <img src={img} title={item.hash_name} />
                </CopyToClipboard>

                <span className="skins-on-bot-on-bot-container-skins_price_bot">Bot<br />{item.SteamID}</span>


                {item.tm_avg && <span className="skins-on-bot-on-bot-container-skins_price_dop">- Tm avg: <span>{item.tm_avg}$</span></span>}
                {item.buff_avg && <span className="skins-on-bot-on-bot-container-skins_price_dop">- Buff avg: <span>{item.buff_avg}$</span></span>}
                {item.buff_min && <span className="skins-on-bot-on-bot-container-skins_price_dop">- Buff min: <span>{item.buff_min}$</span></span>}
                {item.marketplace_order && <span className="skins-on-bot-on-bot-container-skins_price_dop">- Tf order: <span>{item.marketplace_order}$</span></span>}
                {item.marketplace_avg && <span className="skins-on-bot-on-bot-container-skins_price_dop">- Tf avg: <span>{item.marketplace_avg}$</span></span>}

                <span
                  className="skins-on-bot-on-bot-container-skins_price"
                  style={{ color: profitPercent > 0 ? 'rgba(86, 174, 154, 1)' : 'rgb(174, 86, 86)' }}>
                  {item.priceOnTransaction} $ <span>{profitPercent > 0 ? '+' : ''}{profitPercent}% </span>
                </span>

                <ContextMenu id={`contextmenu-${item.assetId}`}>
                  <MenuItem
                    onClick={() =>
                      window.open(
                        `https://steamcommunity.com/market/listings/${item.appid}/${item.hash_name}`,
                        "_blank"
                      )
                    }
                  >
                    Открыть в Steam Market
                  </MenuItem>
                  <MenuItem
                    onClick={() => {
                      switch (item.appid) {
                        case "730":
                          return window.open(
                            `https://market.csgo.com/en/1/1/${item.hash_name}`,
                            "_blank"
                          )

                        case "570":
                          return window.open(
                            `https://market.dota2.net/?search=${item.hash_name}&sd=asc&s=price`,
                            "_blank"
                          )

                        case "440":
                          return window.open(
                            `https://tf2.tm/?search=${item.hash_name}&sd=asc&s=price`,
                            "_blank"
                          )

                        case "252490":
                          return window.open(
                            `https://rust.tm/?search=${item.hash_name}&sd=asc&s=price`,
                            "_blank"
                          )
                        default:
                          break;
                      }
                    }
                    }
                  >
                    Открыть на тм
                  </MenuItem>
                </ContextMenu>
              </div>
            </ContextMenuTrigger>
          })}
        </div>

      </div>

      <Pagination
        pageCount={dataSkins.data?.count / 44}
        handlePageClick={handlePageClick}
        pageIndex={pageNumber - 1}
      />

    </div>
  )
};

export default SkinsOnBotsBlock;
