import React from "react";

const SkinsOnBotsBlockFilter = ({ searchTerms, handleSearchChange }) => {
  return (
    <div className="skins-on-bot-container_filter">
      <input
        value={searchTerms.SteamID}
        onChange={handleSearchChange("hash_name")}
        placeholder="Search by Name"
      />
      <select
        value={searchTerms.game}
        onChange={handleSearchChange("game")}
        defaultValue={"730"}
      >
        <option value="730">CS:GO</option>
        <option value="570">Dota 2</option>
        <option value="252490">Rust</option>
        <option value="440">Team Fortress 2</option>
      </select>
      <select
        value={searchTerms.orderBy}
        onChange={handleSearchChange("orderBy")}
        defaultValue={"createdAt||desc"}
      >
        <option value="priceOnTransaction||desc">От большего к меньшему (цена)</option>
        <option value="priceOnTransaction||asc">От меньшего к большему (цена)</option>
        <option value="createdAt||desc">От нового к старому</option>
      </select>
    </div>
  );
};

export default SkinsOnBotsBlockFilter;
